import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { LayoutDetailPages } from 'hoc/LayoutDetailPages';
import { FormikProvider, useFormik } from 'formik';
import { useMatchMedia } from 'hooks/useMatchMedia';
import {
  fetchOrderType,
  createOrderType,
  updateOrderType,
} from 'store/orderTypes/actions';

import { NavBar } from 'components/Common/NavBar';
import { useTranslation } from 'react-i18next';
import { ORDERTYPES_BREADCRUMBS } from 'common/data/beadcrumbs';
import { OrderTypeMainCard } from 'components/OrderType/OrderTypeMainCard';
import { OrderTypeCountryCard } from 'components/OrderType/OrderTypeCountryCard';
import { OrderTypeApplicantCard } from 'components/OrderType/OrderTypeApplicantCard';
import { OrderTypeDocumentsCard } from 'components/OrderType/OrderTypeDocumentsCard';
import { OrderTypeCountriesCard } from 'components/OrderType/OrderTypeCountriesCard';

import {
  convertOrderTypeFromApi,
  convertOrderTypeToApi,
} from 'helpers/converters';
import { OrderTypeDatesCard } from 'components/OrderType/OrderTypeDatesCard';
import { HeadControlButtons } from 'components/HeadControllButtons/HeadControllButtons';
import Loader from 'components/Common/Loader';
import { Row } from 'reactstrap';

const formikInitialVals = {
  id: null,
  translationEn: '',
  translationDe: '',
  isActive: true,
  enableForIndividual: true,
  enableForLegal: true,

  enableCountry: true,
  availableForCountries: [],

  applicantMaxQuantity: 1,
  enableApplicantBirthdate: false,
  enableApplicantPassport: false,

  enableContactPerson: true,

  enableResidency: false,

  enableDescription: true,

  enableVisaType: false,
  enableVisaUrgency: false,

  enableDocumentsReceivedBy: true,
  enableDeliveryMethod: true,
  // Dates
  enableDocumentsToConsEstimatedAt: true,
  enableDocumentsToConsActualAt: true,
  enableDocumentsFromConsEstimatedAt: true,
  enableDocumentsFromConsActualAt: true,
  enableDocumentsToApplicantEstimatedAt: true,
  enableDocumentsToApplicantActualAt: true,
  enableFirstDateOfEntry: false,
  enableGultigFrom: false,
  enableGultigTo: false,
  documents: null,
  countries: null,
};

const getOrderTypeState = state => state.orderTypes.orderTypes.orderTypeDetail;

const OrderType = () => {
  const { id } = useParams();
  const { isMobile } = useMatchMedia();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderType, isLoading } = useSelector(getOrderTypeState);

  const handleSubmit = values => {
    if (id) {
      dispatch(
        updateOrderType({
          orderType: convertOrderTypeToApi(values),
          id,
        }),
      );
    } else {
      dispatch(
        createOrderType({
          orderType: convertOrderTypeToApi(values),
          // push,
          // redirectPath: values.isRedirect && values.redirectPath,
        }),
      );
    }
  };

  const formik = useFormik({
    initialValues: formikInitialVals,
    validateOnChange: false,
    enableReinitialize: true,
    // validationSchema: SERVICE_VALIDATOR,
    onSubmit: handleSubmit,
  });

  const orderTypeNav = (() => {
    let defaultNav = [
      { title: 'orderTypes.main', id: 1, link: 'mainSettings', offset: -110 },
      { title: 'orderTypes.country', id: 2, link: 'countrySettings' },
      { title: 'orderTypes.applicants', id: 3, link: 'applicantsSettings' },
      { title: 'orderTypes.dates', id: 6, link: 'datesSettings' },
    ];

    if (formik.values.enableCountry) {
      defaultNav.push({
        title: 'orderTypes.countries',
        id: 4,
        link: 'countriesSettings',
      });
    } else {
      defaultNav.push({
        title: 'orderTypes.documents',
        id: 5,
        link: 'documentsSettings',
      });
    }

    defaultNav.sort((a, b) => a.id - b.id);

    return defaultNav;
  })();

  useEffect(() => {
    if (id) {
      dispatch(fetchOrderType(id));
    }
  }, [id]);

  useEffect(() => {
    if (orderType) {
      formik.resetForm({
        values: { ...convertOrderTypeFromApi(orderType) },
      });
    }
  }, [orderType]);

  return (
    <FormikProvider value={formik}>
      <LayoutDetailPages
        layoutConfig={{
          title: t('orderTypes.orderType'),
          isScrollableHeader: true,
          breadcrumbs: ORDERTYPES_BREADCRUMBS,
          extraContent: (
            <div
              className={classNames(
                'd-flex w-100',
                isMobile ? 'justify-content-start' : 'justify-content-end',
              )}
            >
              <HeadControlButtons
                typeCancel="gray"
                handleSubmit={formik.handleSubmit}
                path="/orderTypes"
                isDisabledButton={!formik.dirty || isLoading}
              />
            </div>
          ),
        }}
        navBarContent={<NavBar scrollableHeader components={orderTypeNav} />}
      >
        {isLoading && (
          <Row className="justify-content-center">
            <Loader isLoading={isLoading} />
          </Row>
        )}
        <OrderTypeMainCard />
        <OrderTypeCountryCard />
        <OrderTypeApplicantCard />

        {formik.values.enableCountry && <OrderTypeCountriesCard />}
        {!formik.values.enableCountry && (
          <OrderTypeDocumentsCard
            attached={formik.values.documents?.attached}
            available={formik.values.documents?.available}
          />
        )}
        <OrderTypeDatesCard />
      </LayoutDetailPages>
    </FormikProvider>
  );
};

export default OrderType;
