import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { CardTitle, ShadowCard } from 'components/Common';
import { StyledTable } from 'components/Table/StyledTable';
import { BlueLink } from 'components/Common';
import { OrderTypeEditDocuments } from './OrderTypeEditDocuments';
import { OrderTypeCountryVisaCard } from './OrderTypeCountryVisaCard';

export const OrderTypeCountriesCard = () => {
  const formik = useFormikContext();
  const [selectedCountry, setSelectedCountry] = useState([]);
  const countries = formik.values.countries;

  // collectedDocuments are fetched from backend
  const [collectedDocuments, setCollectedDocuments] = useState([]);

  useEffect(() => {
    let docs = [];

    countries?.map(country => {
      if (country?.visas) {
        country.visas.map(visa =>
          visa.attached.map(doc => doc.conditions.map(cond => docs.push(cond))),
        );
      } else {
        country.attached.map(doc =>
          doc.conditions.map(cond => docs.push(cond)),
        );
      }
    });
    setCollectedDocuments([...collectedDocuments, ...docs]);
  }, [countries]);

  return (
    <ShadowCard
      id="countriesSettings"
      className="mt-4"
      bodyStyle={{ padding: '24px' }}
    >
      <CardTitle title="orderTypes.countries" />

      <StyledTable className="mt-0">
        <tbody>
          {countries?.map((country, index) => (
            <tr key={country.id}>
              <td>
                <BlueLink onClick={() => setSelectedCountry(country.id)}>
                  {index + 1}. {country.name}
                </BlueLink>

                {country.id == selectedCountry &&
                  (country.visas ? (
                    country.visas.map(visa => (
                      <OrderTypeCountryVisaCard
                        key={visa.id}
                        country={country}
                        visa={visa}
                        collectedDocuments={collectedDocuments}
                        setCollectedDocuments={setCollectedDocuments}
                      />
                    ))
                  ) : (
                    <OrderTypeEditDocuments
                      key={country.id}
                      country={country}
                      attached={country.attached}
                      available={country.available}
                      collectedDocuments={collectedDocuments}
                      setCollectedDocuments={setCollectedDocuments}
                    />
                  ))}
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </ShadowCard>
  );
};
