import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Col, Label, Row } from 'reactstrap';
import { StyledTable } from 'components/Table/StyledTable';
import { BlueLink } from 'components/Common';
import { useModal } from 'hooks/useModal';
import { ModalOrderTypeDocumentCondition } from 'components/OrderType/ModalOrderTypeDocumentCondition';
import { useTranslation } from 'react-i18next';

export const OrderTypeEditDocuments = ({
  attached,
  available,
  collectedDocuments,
  setCollectedDocuments,
  country = null,
  visa = null,
}) => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const { handleOpenModal, handleCloseModal, isOpen } = useModal();

  const [attachedDocuments, setAttAchedDocuments] = useState([]);
  const [availableDocuments, setAvailableDocuments] = useState([]);

  useEffect(() => {
    setAttAchedDocuments(
      attached &&
        attached
          .sort((a, b) =>
            a.translations.en.toLowerCase() > b.translations.en.toLowerCase()
              ? 1
              : -1,
          )
          .map(doc => ({
            id: doc.id,
            translations: doc.translations,
            conditions: doc.conditions?.map((cond, index) => ({
              // ...cond,
              country: cond.country,

              description_requirements_de: cond.description_requirements_de,
              description_requirements_en: cond.description_requirements_en,
              description_source_de: cond.description_source_de,
              description_source_en: cond.description_source_en,
              description_title_de: cond.description_title_de,
              description_title_en: cond.description_title_en,

              document: cond.document,
              examplePreview: cond.example,
              if_not_product: cond.if_not_product,
              if_product: cond.if_product,

              id: cond.id,
              index: index + 1,
              is_individual: cond.is_individual,
              is_legal: cond.is_legal,
              order_type: cond.order_type,
              questionnairePreview: cond.questionnaire,

              require_original: cond.require_original,
              require_residency: cond.require_residency,
              valid_nationalities: cond.valid_nationalities,
              visa_duration: cond.visa_duration,
              visa_type: cond.visa_type,
              visum_integration: cond.visum_integration,
            })),
          })),
    );

    setAvailableDocuments(
      available.sort((a, b) =>
        a.translations.en.toLowerCase() > b.translations.en.toLowerCase()
          ? 1
          : -1,
      ),
    );
  }, [formik.values.documents, formik.values.countries]);

  useEffect(() => {
    formik.setFieldValue('attachedConditions', collectedDocuments);
  }, [collectedDocuments]);

  const deleteCondition = (docId, condIndex) => {
    const doc = attachedDocuments.find(doc => doc.id === docId);
    const deletedCondition = doc.conditions.find(
      cond => cond.index === condIndex,
    );

    setCollectedDocuments(
      collectedDocuments.filter(
        cond =>
          !(
            cond.country === deletedCondition.country &&
            cond.document === deletedCondition.document &&
            cond.id === deletedCondition.id &&
            cond.if_product === deletedCondition.if_product &&
            cond.if_not_product === deletedCondition.if_not_product &&
            cond.is_individual === deletedCondition.is_individual &&
            cond.is_legal === deletedCondition.is_legal &&
            cond.visa_duration === deletedCondition.visa_duration &&
            cond.visa_type === deletedCondition.visa_type
          ),
      ),
    );

    const attachedDocument = {
      id: doc.id,
      translations: doc.translations,
      conditions: doc.conditions.filter(cond => cond.index !== condIndex),
    };

    let docs = attachedDocuments.filter(doc => doc.id !== docId);

    if (attachedDocument.conditions?.length >= 1) {
      docs.push(attachedDocument);
    } else {
      setAvailableDocuments([
        ...availableDocuments,
        {
          id: attachedDocument.id,
          translations: attachedDocument.translations,
        },
      ]);
    }
    setAttAchedDocuments(docs.sort((a, b) => b.id - a.id));
  };

  const deleteDocument = docId => {
    const doc = attachedDocuments.find(doc => doc.id === docId);
    setAvailableDocuments(
      [
        ...availableDocuments,
        {
          id: doc.id,
          translations: doc.translations,
        },
      ].sort((a, b) =>
        a.translations.en.toLowerCase() > b.translations.en.toLowerCase()
          ? 1
          : -1,
      ),
    );
    setAttAchedDocuments(
      attachedDocuments
        .filter(doc => doc.id !== docId)
        .sort((a, b) => b.id - a.id),
    );
  };

  const addDocument = docId => {
    const doc = availableDocuments.find(doc => doc.id === docId);

    setAvailableDocuments(availableDocuments.filter(doc => doc.id !== docId));

    setAttAchedDocuments(
      [
        ...attachedDocuments,
        {
          id: doc.id,
          translations: doc.translations,
          conditions: [],
        },
      ].sort((a, b) =>
        a.translations.en.toLowerCase() > b.translations.en.toLowerCase()
          ? 1
          : -1,
      ),
    );

    handleOpenModal({
      order_type: formik.values.id,
      document: doc,
    });
  };

  const closeModal = e => {
    handleCloseModal();
    const doc = attachedDocuments.find(doc => doc.id === isOpen.document.id);

    if (!!e && doc.conditions.length === 0) {
      deleteDocument(isOpen.document.id);
    }
  };

  return (
    <>
      {/* Attached docs */}
      {attachedDocuments?.map((document, documentIndex) => (
        <>
          <Row className="d-flex m-4 flex-wrap">
            <Col xs={10} className="p-0 d-flex align-items-center">
              <Label className="m-0 display-5 text-danger">
                {documentIndex + 1}. {document.translations['en']} (
                {document.translations['de']})
              </Label>
            </Col>
            {document.conditions.length === 0 && (
              <Col>
                <i
                  className="bx bx-trash-alt"
                  style={{ float: 'right' }}
                  onClick={() => deleteDocument(document.id)}
                />
              </Col>
            )}
          </Row>

          <StyledTable className="mb-5 ">
            <thead>
              <tr>
                <th />
                {!!visa && (
                  <>
                    <th>{t('orderTypes.visaType')}</th>
                    <th>{t('orderTypes.visaDuration')}</th>
                  </>
                )}

                <th>{t('orderTypes.requireOriginal')}</th>
                <th>{t('orderTypes.ifService')}</th>
                <th>{t('orderTypes.ifNotService')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {document.conditions.map((condition, conditionIndex) => (
                <tr key={condition.condition?.id}>
                  <td>
                    <BlueLink
                      className="fw-semibold"
                      style={{ fontSize: '14px' }}
                      onClick={() => handleOpenModal({ document, condition })}
                    >
                      #{conditionIndex + 1}.&nbsp;
                    </BlueLink>
                  </td>
                  {!!visa && (
                    <>
                      <td>{visa.name}</td>
                      <td>{condition?.visa_duration?.label}</td>
                    </>
                  )}
                  <td>{condition.require_original ? 'Yes' : 'No'}</td>
                  <td>{condition.if_product?.label}</td>
                  <td>{condition.if_not_product?.label}</td>
                  <td>
                    <i
                      style={{ color: 'red', cursor: 'pointer' }}
                      className="bx bx-trash-alt"
                      onClick={() =>
                        deleteCondition(document.id, condition.index)
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            <a
              style={{ fontSize: '12px' }}
              className="mt-2 d-flex flex-nowrap align-items-center"
              onClick={() => handleOpenModal({ document })}
            >
              <i className="bx bx-plus fs-4 me-2" />
              Add {document.conditions.length > 0 ? 'another' : ''} condition
              for {document.translations['en']}
            </a>
          </StyledTable>
        </>
      ))}

      {/* Available docs */}

      {availableDocuments?.length > 0 && (
        <>
          <Row className="d-flex m-3 mt-5 flex-wrap">
            <Col className="p-0 d-flex align-items-center">
              <Label className="m-0 display-5">
                {t('orderTypes.availableDocuments')}
              </Label>
            </Col>
          </Row>

          <StyledTable>
            <thead>
              <tr>
                <th>EN</th>
                <th>DE</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {availableDocuments?.map((document, availableIndex) => (
                <tr key={document.id}>
                  <td>
                    {availableIndex + 1}. {document.translations['en']}
                  </td>
                  <td>{document.translations['de']}</td>
                  <td>
                    <i
                      title={`add "${document.translations['en']}" to attached documents`}
                      className="bx bx-plus"
                      style={{ color: 'blue', cursor: 'pointer' }}
                      onClick={() => addDocument(document.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </>
      )}

      {isOpen && (
        <ModalOrderTypeDocumentCondition
          handleCloseModal={closeModal}
          isOpenModal={isOpen}
          orderTypeData={formik.values}
          props={isOpen}
          country={country}
          visa={visa}
          attachedDocuments={attachedDocuments}
          availableDocuments={availableDocuments}
          collectedDocuments={collectedDocuments}
          setAttAchedDocuments={setAttAchedDocuments}
          setAvailableDocuments={setAvailableDocuments}
          setCollectedDocuments={setCollectedDocuments}
        />
      )}
    </>
  );
};
