import React from 'react';
import { Alert } from 'reactstrap';
import { OrderTypeEditDocuments } from './OrderTypeEditDocuments';

export const OrderTypeCountryVisaCard = ({
  visa,
  country,
  collectedDocuments,
  setCollectedDocuments,
}) => {
  return (
    <>
      <Alert className="mt-4" color="info">
        {visa.name}
      </Alert>

      <OrderTypeEditDocuments
        country={country}
        visa={visa}
        attached={visa.attached}
        available={visa.available}
        collectedDocuments={collectedDocuments}
        setCollectedDocuments={setCollectedDocuments}
      />
    </>
  );
};
