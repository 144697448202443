import { CardTitle, ShadowCard } from 'components/Common';
import React, { useState, useEffect } from 'react';
import { OrderTypeEditDocuments } from './OrderTypeEditDocuments';

export const OrderTypeDocumentsCard = ({ attached, available }) => {
  const [collectedDocuments, setCollectedDocuments] = useState([]);

  useEffect(() => {
    let docs = [];

    attached.map(doc => doc.conditions.map(cond => docs.push(cond)));

    setCollectedDocuments([...collectedDocuments, ...docs]);
  }, []);

  return (
    <ShadowCard
      id="documentsSettings"
      className="mt-4"
      bodyStyle={{ padding: '24px' }}
    >
      <CardTitle title="orderTypes.documents" />

      <OrderTypeEditDocuments
        attached={attached}
        available={available}
        collectedDocuments={collectedDocuments}
        setCollectedDocuments={setCollectedDocuments}
      />
    </ShadowCard>
  );
};
