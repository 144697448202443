import React from 'react';
import { useFormikContext } from 'formik';

export const OrderTypeFileInput = ({ name, ...props }) => {
  const formik = useFormikContext();

  return (
    <input
      type="file"
      onChange={e => {
        const reader = new FileReader();
        reader.readAsDataURL(e.currentTarget.files[0]);
        reader.onload = () => {
          formik.setFieldValue(`${name}`, reader.result);
        };
      }}
      {...props}
    />
  );
};
