import { del, get, post, put, upload, updateFile } from '../api_helper';
import * as url from '../url-helpers/admin';
import authHeader from '../jwt-token-access/auth-token-header';
import { urlTemplate } from '../url-helpers/urlTemplate';
import {
  ORDER_CUSTOMER,
  ORDER_CUSTOMER_ITEM,
  ORDER_CUSTOMER_STATUS,
  ORGANIZATIONS_URL,
  USERS_URL,
  EXTRACT_PASSPORT_IMG_FROM_PDF_URL,
} from '../url-helpers/admin';

// get Events
export const getEvents = () => get(url.GET_EVENTS);

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } });

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES);

// get chats
export const getChats = ({ payload }) =>
  get(url.GET_CHATS, { headers: authHeader(), params: payload });

export const getChat = id =>
  get(`${url.GET_CHATS}`, { headers: authHeader(), params: { id } });

export const getProfile = () => get(url.GET_PROFILE, { headers: authHeader() });

export const updateProfile = data =>
  put(`${url.GET_PROFILE}`, data, { headers: { ...authHeader() } });

export const changePassword = data =>
  post(`${url.CHANGE_PASSWORD}`, data, { headers: { ...authHeader() } });

export const changeEmail = ({ data }) =>
  post(`${url.CHANGE_EMAIL}`, { ...data }, { headers: { ...authHeader() } });

export const changeChatStatus = ({ chatId, params: { status } }) =>
  put(
    `${url.CHANGE_CHAT_STATUS}${chatId}/status`,
    { status },
    { headers: authHeader() },
  );

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages
export const getMessages = ({ chatId, params }) =>
  get(`${url.GET_MESSAGES}${chatId}`, {
    headers: authHeader(),
    params: { ...params },
  });

// post messages
export const addMessage = ({ formData }) =>
  upload(`${url.ADD_MESSAGE}`, formData, {
    headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' },
  });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get wallet
export const getWallet = () => get(url.GET_WALLET);

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS);

// get invoices
export const getInvoices = () =>
  get(url.OPERATOR_INVOICES_LIST, { headers: authHeader() });

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.OPERATOR_INVOICES_LIST}/${id}/`, { headers: authHeader() });

// generate new invoice
export const generateInvoice = params =>
  post(`${url.OPERATOR_INVOICES_GENERATE}/`, params, {
    headers: authHeader(),
  });

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// get tasks
export const getTasks = () => get(url.GET_TASKS);

// get admin catalog
export const getUsers = params =>
  get(url.USERS_URL, { headers: authHeader(), params });
export const getUser = param =>
  get(`${url.USERS_URL}${param}/`, { headers: authHeader() });
export const updateUser = ({ id, user }) =>
  put(`${url.USERS_URL}${id}/`, user, { headers: authHeader() });
export const createUser = ({ user }) =>
  post(`${url.USERS_URL}`, user, { headers: authHeader() });
export const blockUser = params =>
  del(`${url.USERS_URL}${params.payload.id}/`, { headers: authHeader() });
export const unBlockUser = params =>
  post(
    `${url.USERS_URL}${params.payload.id}/unblock/`,
    {},
    { headers: authHeader() },
  );
export const inviteUserRequest = id =>
  post(`${url.USERS_URL}${id}/invite/`, {}, { headers: authHeader() });

export const getServices = params =>
  get(url.OPERATOR_SERVICES_URL, { headers: authHeader(), params });

export const getTariffs = params =>
  get(url.GET_TARIFFS, { headers: authHeader(), params });

export const createTariff = params =>
  post(url.GET_TARIFFS, params, {
    headers: authHeader(),
  });

export const getTariff = id =>
  get(`${url.GET_TARIFFS}${id}/`, { headers: authHeader(), id });

export const getCurrentTariffServices = ({ id, idExclude, params }) =>
  get(
    `${url.GET_TARIFFS_PRODUCT}?tariff=${id}&tariff_exclude=${idExclude || ''}`,
    {
      headers: authHeader(),
      params,
    },
  );

export const updateTariff = tariff =>
  put(`${url.GET_TARIFFS}${tariff.id}/`, tariff, { headers: authHeader() });

export const deleteTariff = id =>
  del(`${url.GET_TARIFFS}${id}/`, { headers: authHeader() });

export const addServices = (
  tariffId,
  products,
  selectedServicesAll,
  fromTariffId,
  params,
) =>
  post(
    `${url.GET_TARIFFS}${tariffId}/products/import/`,
    {
      products,
      selected_services_all: selectedServicesAll,
      tariff_id: fromTariffId,
    },
    {
      headers: authHeader(),
      params: params,
    },
  );

export const getTariffServices = ({ id, params }) =>
  get(`${url.GET_TARIFFS}${id}/products/import/?`, {
    headers: authHeader(),
    params,
  });

export const deleteTariffServices = ({ id, selectedServices }) =>
  del(`${url.GET_TARIFFS}${id}/products/delete/`, {
    headers: authHeader(),
    data: { products: selectedServices },
  });

export const updatePriceTariffService = ({ service }) =>
  put(`${url.GET_TARIFFS_PRODUCT}${service.id}/`, service, {
    headers: authHeader(),
  });

export const getFilteredServices = params =>
  post(`${url.OPERATOR_SERVICES_URL}filter/`, params, {
    headers: authHeader(),
  });

export const getTags = params =>
  get(`${url.TAGS}`, { headers: authHeader(), params });

export const fetchDetailedService = ({ serviceId, serviceType }) => {
  return get(`${url.OPERATOR_SERVICES_URL}${serviceType}/${serviceId}/`, {
    headers: authHeader(),
  });
};

export const updateServiceDetails = ({ serviceId, serviceType, data }) =>
  put(`${url.OPERATOR_SERVICES_URL}${serviceType}/${serviceId}/`, data, {
    headers: authHeader(),
  });

export const createService = (params, serviceType) =>
  post(`${url.OPERATOR_SERVICES_URL}${serviceType}/`, params, {
    headers: authHeader(),
  });

export const getService = param =>
  get(`${url.SERVICE_URL}${param}`, { headers: authHeader() });
export const updateService = service =>
  put(`${url.SERVICE_URL}${service.id}/`, service, { headers: authHeader() });

export const getOrganizations = params =>
  get(url.ORGANIZATIONS_URL, { headers: authHeader(), params });
export const getOrganization = param =>
  get(`${url.ORGANIZATIONS_URL}${param}/`, { headers: authHeader() });
export const updateOrganization = ({ id, organization }) =>
  put(`${url.ORGANIZATIONS_URL}${id}/`, organization, {
    headers: authHeader(),
  });
export const createOrganization = ({ organization }) =>
  post(`${url.ORGANIZATIONS_URL}`, organization, { headers: authHeader() });
export const createOrganizationManager = ({ organizationId, userId }) =>
  post(
    `${url.ORGANIZATIONS_URL}${organizationId}/attach/${userId}`,
    {},
    { headers: authHeader() },
  );

export const getLogs = params =>
  get(url.GET_LOGS, { headers: authHeader(), params });

// references
export const getVisaTypes = query =>
  get(url.VISA_TYPES, { headers: authHeader(), params: query });
export const getCountries = params =>
  get(url.GET_COUNTRIES, {
    headers: authHeader(),
    params: { page_size: 5000, ...params },
  });
export const getCities = ({ countryId }) =>
  get(`${url.GET_COUNTRIES}${countryId}/cities`, {
    headers: authHeader(),
    params: { page_size: 5000 },
  });
export const getAddresses = params =>
  post(url.GET_ADDRESSES, params, { headers: authHeader() });
export const getGoogleAddress = ({ place_id }) =>
  post(`${url.GET_ADDRESSES}google/${place_id}`, {}, { headers: authHeader() });

export const getReferencesConfig = params =>
  get(url.GET_REFERENCES_CONFIG, {
    headers: authHeader(),
    params,
  });

export const getUrgenciesRequest = params =>
  get(url.GET_VS_URGENCY_LIST, { headers: authHeader(), params });

export const getRegionsData = () =>
  get(url.GET_REGIONS, { headers: authHeader() });

export const getOrders = params =>
  get(url.ORDERS_URL, { headers: authHeader(), params });
export const getOrdersFeed = params =>
  get(url.ORDERS_FEED_URL, { headers: authHeader(), params });

export const createOrder = order =>
  post(`${url.ORDERS_URL}`, order, { headers: authHeader() });

export const getOrder = param =>
  get(`${url.ORDERS_URL}${param}/`, { headers: authHeader() });

export const updateOrder = ({ id, order }) =>
  put(`${url.ORDERS_URL}${id}/`, order, { headers: authHeader() });

export const ordersCreateCustomer = ({ id, data }) =>
  post(
    urlTemplate(ORDER_CUSTOMER, {
      id,
    }),
    data,
    { headers: authHeader() },
  );

export const ordersUpdateCustomer = ({ id, customer_pk, data }) =>
  put(
    urlTemplate(ORDER_CUSTOMER_ITEM, {
      id,
      customer_pk,
    }),
    data,
    { headers: authHeader() },
  );

export const ordersDeleteCustomer = ({ id, customer_pk }) =>
  del(
    urlTemplate(ORDER_CUSTOMER_ITEM, {
      id,
      customer_pk,
    }),
    { headers: authHeader() },
  );

export const ordersStatusChange = ({ id, customer_pk, status }) =>
  put(
    urlTemplate(ORDER_CUSTOMER_STATUS, {
      id,
      customer_pk,
    }),
    { status },
    { headers: authHeader() },
  );

export const createComment = ({ id, formData }) =>
  upload(`${url.ORDERS_URL}${id}/comments/`, formData, {
    headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' },
  });
export const updateComment = ({ orderId, commentId, formData }) =>
  put(`${url.ORDERS_URL}${orderId}/comments/${commentId}/`, formData, {
    headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' },
  });

export const getComments = ({ id, params }) =>
  get(`${url.ORDERS_URL}${id}/comments/`, { headers: authHeader(), params });

export const uploadDocument = ({ id, userId, formData }) =>
  upload(`${url.ORDERS_URL}${id}/${userId}/`, formData, {
    headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' },
  });

export const getTaxes = params =>
  get(`${url.TAX}/`, { headers: authHeader(), params });
export const getTaxesList = params =>
  get(`${url.TAX_LIST}/`, { headers: authHeader(), params });
export const getTax = param =>
  get(`${url.TAX}/${param}/`, { headers: authHeader() });
export const updateTax = ({ id, tax }) =>
  put(`${url.TAX}/${id}/`, tax, { headers: authHeader() });
export const createTax = tax =>
  post(`${url.TAX}/`, tax, { headers: authHeader() });

export const getWizard = ({ country, organization, customer }) =>
  get(`/operator/wizards/${country}/${organization}/${customer}/`, {
    headers: authHeader(),
  });
export const getWizardStep = ({ country, organization, customer, step }) =>
  get(`/operator/wizards/${country}/${organization}/${customer}/step/${step}`, {
    headers: authHeader(),
  });
export const getNextStepWizard = ({
  country,
  organization,
  customer,
  step,
  data,
}) =>
  post(
    `/operator/wizards/${country}/${organization}/${customer}/step/${step}`,
    { data },
    { headers: authHeader() },
  );
export const uploadWizardFile = ({
  country,
  organization,
  customer,
  field,
  formData,
}) =>
  upload(
    `/operator/wizards/${country}/${organization}/${customer}/document/${field}/`,
    formData,
    { headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' } },
  );
export const getValidateStepWizard = ({
  country,
  organization,
  customer,
  step,
  data,
}) =>
  put(
    `/operator/wizards/${country}/${organization}/${customer}/step/${step}`,
    { data },
    { headers: authHeader() },
  );

//old
export const getUserProfile = () => get(url.GET_USER_PROFILE);

export const getManagerInvites = ({ ...params }) =>
  get(`${url.MANAGER_INVITES_LIST}`, { headers: authHeader(), params });
export const createInvite = invite =>
  post(`${url.MANAGER_CREATE_INVITE}`, invite, { headers: authHeader() });

export const getOperatorInvites = ({ organization, ...params }) =>
  get(`${url.OPERATOR_INVITES_LIST}/${organization}/`, {
    headers: authHeader(),
    params,
  });
export const createOperatorInvite = ({ organization, ...params }) =>
  post(`${url.OPERATOR_CREATE_INVITE}/${organization}/`, params, {
    headers: authHeader(),
  });

export const getOperatorInvoices = params =>
  get(`${url.OPERATOR_INVOICES_LIST}/`, { headers: authHeader(), params });
export const createOperatorInvoice = params =>
  post(`${url.OPERATOR_INVOICES_LIST}/`, params, { headers: authHeader() });
export const getOperatorInvoice = ({ id, ...params }) =>
  get(`${url.OPERATOR_INVOICES_LIST}/${id}`, { headers: authHeader(), params });
export const updateOperatorInvoice = (id, params) =>
  put(`${url.OPERATOR_INVOICES_LIST}/${id}`, params, { headers: authHeader() });
export const updateOperatorInvoiceStatus = (id, new_status) =>
  put(
    `${url.OPERATOR_INVOICES_LIST}/${id}/status`,
    { new_status },
    { headers: authHeader() },
  );

export const paySofortInit = bill_id =>
  post(`${url.PAY_SOFORT_INIT}/${bill_id}/`, {}, { headers: authHeader() });
export const paySofortHook = id =>
  get(`${url.PAY_SOFORT_HOOK}/${id}/`, { headers: authHeader() });
export const paySofortAbort = id =>
  get(`${url.PAY_SOFORT_ABORT}/${id}/`, { headers: authHeader() });
export const paySofortWebHook = () =>
  post(`${url.PAY_SOFORT_WEBHOOK}`, {}, { headers: authHeader() });

export const payStripeInit = bill_id =>
  post(`${url.PAY_STRIPE_INIT}/${bill_id}/`, {}, { headers: authHeader() });
export const payStripeWebhook = bill_id =>
  post(`${url.PAY_STRIPE_WEBHOOK}/${bill_id}/`, {}, { headers: authHeader() });

export const registerInvitedUser = userData =>
  post(`${url.REGISTER_INVITED_USER}`, userData, {});

export const downloadInvoicePDF = (id, params) =>
  get(`${url.OPERATOR_INVOICES_LIST}/${id}/pdf`, {
    headers: authHeader(),
    ...params,
  });

export const getUserOrders = params =>
  get(url.USER_GET_ORDER_LIST, { headers: authHeader(), params });
export const getUserOrder = params =>
  get(url.USER_GET_ORDER + params.id, { headers: authHeader() });

export const operatorApplicantsOrganizationList = ({ organization_pk }) =>
  get(
    urlTemplate(url.OPERATOR_APPLICANTS_ORGANIZATION_LIST, { organization_pk }),
    { headers: authHeader() },
  );

export const operatorApplicantsUserList = ({ user_pk }) =>
  get(urlTemplate(url.OPERATOR_APPLICANTS_USER_LIST, { user_pk }), {
    headers: authHeader(),
  });

export const operatorOrganizationAddApplicants = (organization_pk, data) =>
  post(
    urlTemplate(url.OPERATOR_ORGANIZATION_ADD_APPLICANT, { organization_pk }),
    data,
    {
      headers: authHeader(),
    },
  );

export const operatorUserAddApplicants = (user_pk, data) =>
  post(urlTemplate(url.OPERATOR_USER_ADD_APPLICANT, { user_pk }), data, {
    headers: authHeader(),
  });

export const getDeliveryMethods = ({
  enabled,
  carrier,
  page,
  page_size,
} = {}) =>
  get(url.OPERATOR_DELIVERY_METHODS, {
    headers: authHeader(),
    params: {
      enabled,
      carrier,
      page,
      page_size,
    },
  });

export const deleteDelivery = id =>
  del(urlTemplate(url.OPERATOR_DELIVERY_SHIPMENT, { id }), {
    headers: authHeader(),
  });

export const getDeliveryAddresses = ({ page, page_size } = {}) =>
  get(url.OPERATOR_DELIVERY_ADDRESSES, {
    headers: authHeader(),
    params: {
      page,
      page_size,
    },
  });

export const getDeliveryShipments = (params = {}) =>
  get(url.OPERATOR_DELIVERY_SHIPMENTS, {
    headers: authHeader(),
    params,
  });

export const getDeliveryShipment = id =>
  get(urlTemplate(url.OPERATOR_DELIVERY_SHIPMENT, { id }), {
    headers: authHeader(),
  });

export const createDeliveryShipments = deliveryShipment =>
  post(url.OPERATOR_DELIVERY_SHIPMENTS, deliveryShipment, {
    headers: authHeader(),
  });

export const updateDeliveryShipments = (id, deliveryShipment) =>
  put(urlTemplate(url.OPERATOR_DELIVERY_SHIPMENT, { id }), deliveryShipment, {
    headers: authHeader(),
  });

export const deliveryCreateUps = ({ id }) =>
  post(
    urlTemplate(url.OPERATOR_DELIVERY_SHIPMENT_UPS, { id }),
    {},
    {
      headers: authHeader(),
    },
  );

export const deliveryGetUps = ({ id }) =>
  get(urlTemplate(url.OPERATOR_DELIVERY_SHIPMENT_UPS, { id }), {
    headers: authHeader(),
  });

export const getShipmentLabelRequest = id =>
  get(urlTemplate(url.OPERATOR_DELIVERY_SHIPMENT_LABEL, { id }), {
    headers: authHeader(),
    responseType: 'blob',
    'Content-Type': 'application/pdf',
  });

export const getClients = () => get(url.GET_CLIENTS, { headers: authHeader() });

export const createClient = client =>
  post(url.CREATE_CLIENT, client, { headers: authHeader() });

export const getClientDetails = client =>
  get(
    urlTemplate(url.GET_CLIENT_DETAIL, {
      client_type: client.client_type,
      client_id: client.id,
    }),
    { headers: authHeader() },
  );

export const deleteClientRequest = client => {
  const client_type = client.client_type.client_type;
  let base_url = client_type === 'individual' ? USERS_URL : ORGANIZATIONS_URL;
  const request_url = `${base_url}${client.id}/archive/`;
  return post(request_url, _, { headers: authHeader() });
};

export const deleteClientApplicantRequest = id =>
  post(`${url.OPERATOR_APPLICANT}/${id}/archive/`, _, {
    headers: authHeader(),
  });

export const changeApplicant = (applicant, id) =>
  put(`${url.OPERATOR_APPLICANT}/${id}/`, applicant, { headers: authHeader() });

export const getApplicant = id =>
  get(`${url.OPERATOR_APPLICANT}/${id}/`, { headers: authHeader() });

export const getIndividual = id =>
  get(`${url.GET_INDIVIDUAL}/${id}/`, { headers: authHeader() });

export const updateIndividual = ({ individualId, data }) =>
  put(`${url.GET_INDIVIDUAL}/${individualId}/`, data, {
    headers: authHeader(),
  });

export const operatorCreateInvite = id =>
  post(`${url.OPERATOR_ADD_INVITE}/${id}/invite/`, _, {
    headers: authHeader(),
  });

export const operatorResetPassword = userId =>
  post(`${url.OPERATOR_ADD_INVITE}/${userId}/password_reset/`, _, {
    headers: authHeader(),
  });

export const uploadOrderApplicantDocuments = (applicantId, data) => {
  // return upload(`/operator/applicants/${applicantId}/documents/`, data, {
  return upload(url.getDocumentUploadUrl(applicantId), data, {
    headers: {
      ...authHeader(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getUserInfo = token =>
  get(urlTemplate(url.USER_PASSWORD, { token }), { headers: authHeader() });

export const setUserPassword = (token, password) =>
  post(
    urlTemplate(url.USER_PASSWORD, { token }),
    { password },
    { headers: authHeader() },
  );

export const changeInvoiceStatus = (id, status) =>
  put(urlTemplate(url.CHANGE_INVOICE, { id }), status, {
    headers: authHeader(),
  });

export const getClientFilter = params =>
  get(`${url.GET_CLIENTS}`, {
    headers: authHeader(),
    params,
  });

export const deleteApplicantDocument = (id, document_id) =>
  del(urlTemplate(url.APPLICANT_DOCUMENT, { id, document_id }), {
    headers: authHeader(),
  });

export const getApplicantDocument = (id, document_id, params) =>
  get(urlTemplate(url.APPLICANT_DOCUMENT, { id, document_id }), {
    headers: authHeader(),
    ...params,
  });

export const getApplicantDocumentFilter = params =>
  post(url.APPLICANT_DOCUMENT_FILTERED, params, { headers: authHeader() });

export const uploadChangeApplicantDocuments = (id, document_id, data) =>
  updateFile(urlTemplate(url.APPLICANT_DOCUMENT, { id, document_id }), data, {
    headers: {
      ...authHeader(),
      'Content-Type': 'multipart/form-data',
    },
  });

export const fetchAggregatedInvoices = params =>
  get(url.AGGREGATED_INVOICES, {
    headers: authHeader(),
    params,
  });

export const fetchAggregatedInvoice = id =>
  get(`${url.AGGREGATED_INVOICES}${id}/`, {
    headers: authHeader(),
  });

export const postAggregatedInvoice = invoice =>
  post(`${url.AGGREGATED_INVOICES}scan/`, invoice, {
    headers: authHeader(),
  });

export const deleteAggregatedInvoice = id =>
  del(`${url.AGGREGATED_INVOICES}${id}/`, {
    headers: authHeader(),
  });

export const updateStatusAggregatedInvoice = ({ id, status }) =>
  put(
    `${url.AGGREGATED_INVOICES}${id}/`,
    { new_status: status },
    {
      headers: authHeader(),
    },
  );

export const getInvoicesForAggregate = params =>
  get(
    params.id
      ? `${url.AGGREGATED_INVOICES}${params.id}/scan/`
      : `${url.AGGREGATED_INVOICES}scan/`,
    {
      headers: authHeader(),
      params,
    },
  );

export const downloadAggregateInvoiceFile = (id, typeFile, params) =>
  get(`${url.AGGREGATED_INVOICES}${id}/${typeFile}/`, {
    headers: authHeader(),
    ...params,
  });

export const updateAggregatedInvoice = (id, values) =>
  put(`${url.AGGREGATED_INVOICES}${id}/scan/`, values, {
    headers: authHeader(),
  });

export const deleteInvoiceFromAggregate = (id, invoiceId) =>
  post(`${url.AGGREGATED_INVOICES}${id}/detach/${invoiceId}/`, '', {
    headers: authHeader(),
  });

export const deleteUserRequest = id =>
  post(`${url.USERS_URL}${id}/archive/`, _, { headers: authHeader() });

export const downloadDatevFile = period =>
  post(url.ACCOUNTING_CODES_URL, period, {
    headers: authHeader(),
  });

export const assignToMeRequest = id =>
  post(`${url.ORDERS_URL}${id}/`, _, { headers: authHeader() });

export const completeOrderInvitationRequest = (id, data) =>
  post(`${url.ORDERS_URL}${id}/complete_order_invitation/`, data, {
    headers: authHeader(),
  });

export const downloadOrderLisrResultsXls = (data, params) =>
  post(`${url.ORDERS_URL}results_to_xls/`, data, {
    headers: authHeader(),
    ...params,
  });

export const orderApplicantOrderFormDownloadRequest = (
  orderId,
  customerId,
  fileType,
  params,
) =>
  get(
    `${url.ORDERS_URL}${orderId}/customers/${customerId}/order_form/${fileType}/`,
    { headers: authHeader(), ...params },
  );

export const deleteServiceRequest = id =>
  post(`operator/products/${id}/archive/`, _, { headers: authHeader() });

export const passportScannerRequest = formData =>
  upload(url.PASSPORT_SCANNER_URL, formData, {
    headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' },
  });

export const passportScannerCheckRequest = task_id =>
  post(`${url.PASSPORT_SCANNER_CHECK_URL}${task_id}/`, _, {
    headers: { ...authHeader() },
  });

export const downloadOrderLisrResultsPdf = (data, params) =>
  post(`${url.ORDERS_URL}results_to_pdf/`, data, {
    headers: authHeader(),
    ...params,
  });

// Order types

export const getOrderTypesRequest = params =>
  get(`${url.GET_ORDER_TYPES_URL}`, { headers: authHeader(), ...params });

export const getOrderTypeRequest = (id, params) =>
  get(`${url.GET_ORDER_TYPES_URL}${id}/`, { headers: authHeader(), ...params });

export const createOrderTypeRequest = orderType =>
  post(`${url.GET_ORDER_TYPES_URL}`, orderType, { headers: authHeader() });

export const updateOrderTypeRequest = ({ id, orderType }) =>
  put(`${url.GET_ORDER_TYPES_URL}${id}/`, orderType, { headers: authHeader() });

export const getOrderTypeServicesRequest = (id, params) =>
  get(`/operator/order_types/${id}/products/`, {
    headers: authHeader(),
    params: { ...params },
  });

export const getVisumIOIntegrationListRequest = () =>
  get('/references/visum_io/', { headers: authHeader() });

export const deleteApplicantDocumentRequest = id =>
  del(`/operator/documents/${id}/delete/`, { headers: authHeader() });

export const extractPassportImgFromPdfRequest = data =>
  post(`${EXTRACT_PASSPORT_IMG_FROM_PDF_URL}`, data, {
    headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' },
  });
