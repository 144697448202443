import {
  EMPTY_VALUE_WITH_LABEL,
  FIRST_PAGE,
  PAGES,
  VAT_STATUSES,
} from 'common/data/constants';
import { SERVICE_TYPES } from 'common/data/services';
import { CardTitle, PerPageSelect, ShadowCard } from 'components/Common';
import { SearchInput } from 'components/SearchInput/SerchInput';
import { StatusPicker } from 'components/StatusPicker/StatusPicker';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { AddServiceButton } from './AddServiceButton';
import { TariffAddServiceTable } from './TariffAddServiceTable';
import { TariffServiceTable } from './TariffServiceTable';
import SelectTariff from 'components/Common/SelectTariff';
import { ResponsiveFilters } from 'components/Common/ResponsiveFilters';
import { StyledButtonCreate } from 'components/Common/ButtonCreate';
import {
  addServices,
  getVisaTypes,
  getRegionsData,
} from 'helpers/api-requests/admin';
import { convertPriceToAPI } from 'helpers/converters';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';
import { useCountries } from '../../hooks/useCountries';
// import debounce from 'lodash/debounce';
import { Link } from 'react-scroll';
import classNames from 'classnames';
import styled from 'styled-components';
import i18n from 'i18n';

const StyledText = styled.span`
  height: 40px;
  font-size: 14px;
  color: #6a7187;
  padding: 10px 8px;
  cursor: pointer;
  background-color: powderblue;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
`;

export const TariffServiceCard = ({ tags }) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { id } = useParams();

  const { countriesOptionsShort } = useCountries(true);

  const [isOpedModeAddService, setIsOpenModeAddService] = useState(
    state?.isAddService,
  );

  const DEFAULT_SEARCH_VALS = {
    // perPage: PAGES[0],
    page: FIRST_PAGE,
    search: '',
    serviceType: EMPTY_VALUE_WITH_LABEL,
    country: EMPTY_VALUE_WITH_LABEL,
    visaType: EMPTY_VALUE_WITH_LABEL,
    visaDuration: EMPTY_VALUE_WITH_LABEL,
    visaUrgency: EMPTY_VALUE_WITH_LABEL,
    region: EMPTY_VALUE_WITH_LABEL,
    vat: EMPTY_VALUE_WITH_LABEL,
    tag: '',
  };

  const [searchState, setSearchState] = useState({
    perPage: PAGES[0],
    ...DEFAULT_SEARCH_VALS,
  });
  const [tariffForCopy, setTariffForCopy] = useState(id);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServicesAll, setSelectedServicesAll] = useState(false);
  const [activeTag, setActiveTag] = useState('');

  const handleChangeSearchParam = (key, value) => {
    setSearchState(prev => ({ ...prev, page: FIRST_PAGE, [key]: value }));
  };

  // If search filters are used.
  const [searchHasValue, setSearchHasValue] = useState(false);
  const resetFilters = () => {
    setSearchState({ perPage: searchState.perPage, ...DEFAULT_SEARCH_VALS });
    setActiveTag('');
  };

  useEffect(() => {
    setSearchHasValue(
      activeTag !== '' ||
        searchState.country.value !== '' ||
        searchState.search?.length > 0 ||
        searchState.serviceType.value !== '' ||
        searchState.region.value !== '' ||
        searchState.vat.value !== '',
    );
  }, [searchState]);

  const handleSaveServices = () => {
    addServices(
      id,
      selectedServices.map(service => ({
        product: service.id,
        price:
          convertPriceToAPI(service.price || service.priceDefault || '') ?? '',
      })),
      selectedServicesAll,
      tariffForCopy,
      {
        product_type: searchState.serviceType.value,
        country: searchState.country.value,
        // todo? add new params?
        search: searchState.search,
      },
    )
      .then(response => {
        if (response.success) {
          showToastSuccess(t('tariffs.tariffServicesUpdated'));
          setIsOpenModeAddService(false);
        } else {
          showToastError(response.message);
        }
      })
      .catch(error => showToastError(error));
  };

  const getTags = () => {
    return tags?.length > 0
      ? [
          { label: 'All', value: '' },
          ...tags.map(tag => ({
            label: tag,
            value: tag,
          })),
        ]
      : [];
  };

  const handleChangeTag = tag => {
    handleChangeSearchParam('page', FIRST_PAGE);
    handleChangeSearchParam('tag', tag.value);
    setActiveTag(tag.value);
  };

  const [visaTypeList, setVisaTypeList] = useState([EMPTY_VALUE_WITH_LABEL]);
  const resetVisaType = () => {
    handleChangeSearchParam('visaType', null);
    setVisaTypeList([]);
  };

  useEffect(() => {
    if (!searchState?.country?.value) {
      resetVisaType();
    } else {
      getVisaTypeList();
    }
  }, [searchState?.country]);

  const getVisaTypeList = () => {
    if (searchState.country.value) {
      getVisaTypes({
        country: searchState.country.value,
      })
        .then(response => {
          const results = response?.data?.results ?? [];

          if (results.length > 0) {
            const visaTypes = results.map(item => ({
              label: item.translations[i18n.language],
              value: item.id,
              durations: item?.durations,
              urgencies: item?.urgencies,
            }));
            setVisaTypeList(visaTypes);
            return;
          }
          setVisaTypeList([]);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const [visaDurationList, setVisaDurationList] = useState([]);
  const resetVisaDuration = () => {
    handleChangeSearchParam('visaDuration', null);
    setVisaDurationList([]);
  };

  const [visaUrgencyList, setVisaUrgencyList] = useState([]);
  const resetVisaUrgency = () => {
    handleChangeSearchParam('visaUrgency', null);
    setVisaUrgencyList([]);
  };

  const [regionList, setRegionList] = useState([]);
  const getRegionsList = () => {
    getRegionsData()
      .then(response => {
        const results = response?.data?.results ?? [];
        if (results.length > 0) {
          const regions = results.map(region => ({
            value: region.id,
            label: region.name,
          }));
          setRegionList(regions);
          return;
        }
        setRegionList([]);
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    getRegionsList();
  }, []);

  return (
    <ShadowCard bodyStyle={{ padding: '24px' }} className="mt-4">
      <Row>
        <Col xs={12}>
          <CardTitle
            extraContent={
              isOpedModeAddService ? (
                <AddServiceButton
                  isActiveAdd={selectedServices.length > 0}
                  handleAddService={handleSaveServices}
                  handleCancelAddService={() => {
                    setIsOpenModeAddService(false);
                    setTariffForCopy(id);
                  }}
                />
              ) : (
                <div className="w-100 d-flex align-items-center justify-content-end">
                  <StyledButtonCreate
                    color="primary"
                    className="py-2 px-3"
                    onClick={() => setIsOpenModeAddService(true)}
                  >
                    <i className="bx bx-plus me-1" /> {t('common.add')}
                  </StyledButtonCreate>
                </div>
              )
            }
            title={t('sideBar.services')}
          />
        </Col>

        <div className="d-flex align-items-center mb-4">
          <Col xs={12}>
            <ResponsiveFilters
              lastItem={
                <PerPageSelect
                  perPage={searchState.perPage}
                  setPerPage={value =>
                    handleChangeSearchParam('perPage', value)
                  }
                  setPage={value => handleChangeSearchParam('page', value)}
                />
              }
            >
              <div className="me-3 mb-3">
                <SearchInput
                  // defaultValue={searchState.search}
                  value={searchState.search}
                  // handleChange={debounce(
                  //   event => handleChangeSearchParam('search', event.target.value),
                  //   5000,
                  // )}
                  handleChange={
                    // debounce(
                    event =>
                      handleChangeSearchParam('search', event.target.value)
                    // ,50
                    // )
                  }
                />
              </div>

              {/* <Col xs={isOpedModeAddService ? 5 : 2} className="d-flex "> */}

              {isOpedModeAddService && (
                <div className="me-3 mb-3">
                  <SelectTariff
                    type="dropdown"
                    value={tariffForCopy}
                    excludedId={id}
                    placeholder="tariffs.copyTariff"
                    className="ms-3"
                    handleChange={event => {
                      handleChangeSearchParam('page', FIRST_PAGE);

                      if (!event) {
                        setTariffForCopy(id);
                        return;
                      }

                      setTariffForCopy(event?.value);
                    }}
                  />
                </div>
              )}

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="common.type"
                  className="ms-3"
                  $type="gray"
                  statuses={SERVICE_TYPES}
                  handlePickStatus={value =>
                    handleChangeSearchParam('serviceType', value)
                  }
                  value={searchState.serviceType}
                />
              </div>

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="common.country"
                  $type="gray"
                  statuses={countriesOptionsShort}
                  handlePickStatus={value => {
                    handleChangeSearchParam('country', value);
                    resetVisaType();
                  }}
                  value={searchState.country}
                  className="ms-3"
                />
              </div>

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="services.visaType"
                  $type="gray"
                  statuses={visaTypeList}
                  disabled={!searchState?.country?.value}
                  handlePickStatus={value => {
                    handleChangeSearchParam('visaType', value);
                    resetVisaDuration();
                    resetVisaUrgency();

                    const durations =
                      value?.durations?.map(item => ({
                        value: item.id,
                        label: item.translations[i18n.language],
                      })) || [];
                    setVisaDurationList(durations);

                    const urgencies =
                      value?.urgencies?.map(item => ({
                        value: item.id,
                        label: item.translations[i18n.language],
                      })) || [];
                    setVisaUrgencyList(urgencies);
                  }}
                  value={searchState.visaType}
                  className="ms-3"
                />
              </div>

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="services.visaDuration"
                  $type="gray"
                  statuses={visaDurationList}
                  disabled={!searchState?.visaType?.value}
                  handlePickStatus={value =>
                    handleChangeSearchParam('visaDuration', value)
                  }
                  value={searchState.visaDuration}
                  className="ms-3"
                />
              </div>

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="services.visaUrgency"
                  $type="gray"
                  statuses={visaUrgencyList}
                  disabled={!searchState?.visaType?.value}
                  handlePickStatus={value =>
                    handleChangeSearchParam('visaUrgency', value)
                  }
                  value={searchState.visaUrgency}
                  className="ms-3"
                />
              </div>

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="common.region"
                  $type="gray"
                  statuses={regionList}
                  handlePickStatus={value =>
                    handleChangeSearchParam('region', value)
                  }
                  value={searchState.region}
                  className="ms-3"
                />
              </div>

              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder="invoices.vat"
                  $type="gray"
                  classNameButton="w-100"
                  statuses={VAT_STATUSES}
                  handlePickStatus={value =>
                    handleChangeSearchParam('vat', value)
                  }
                  value={searchState.vat}
                  className="ms-3"
                />
              </div>

              <div className="me-3 mb-3">
                {searchHasValue && (
                  <StyledText onClick={resetFilters} className="ms-3">
                    {t('common.resetFilters')}
                  </StyledText>
                )}
              </div>
            </ResponsiveFilters>
          </Col>
        </div>

        {!isOpedModeAddService && tags && (
          <Col xs={12}>
            <div className="d-flex align-items-center mb-4">
              {getTags().map(tag => (
                <Link
                  key={tag.value}
                  className={classNames(
                    'pr-5',
                    activeTag === tag.value &&
                      'font-weight-bold text-primary text-decoration-underline',
                  )}
                  onClick={() => handleChangeTag(tag)}
                >
                  {tag.label}
                </Link>
              ))}
            </div>
          </Col>
        )}

        <Col xs={12}>
          {isOpedModeAddService ? (
            <TariffAddServiceTable
              searchParams={searchState}
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
              selectedServicesAll={selectedServicesAll}
              setSelectedServicesAll={setSelectedServicesAll}
              selectedTariffId={tariffForCopy}
              setPage={val =>
                setSearchState(prev => ({
                  ...prev,
                  page: val,
                }))
              }
            />
          ) : (
            <TariffServiceTable
              searchParams={searchState}
              selectedTariffId={id}
              setPage={val =>
                setSearchState(prev => ({
                  ...prev,
                  page: val,
                }))
              }
            />
          )}
        </Col>
      </Row>
    </ShadowCard>
  );
};
