import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Input,
  Label,
  Row,
} from 'reactstrap';
import draftToHtml from 'draftjs-to-html';
import { DraftEditor } from 'components/DraftEditor';
import styled, { css } from 'styled-components';
import { StyledButtonCancel } from '../Common/StyledButtonSave';
import { FormikProvider, useFormik } from 'formik';
import { VisaDurationSelector } from 'components/VisaSelectors/VisaDurationSelector';
import { getVisaTypes } from 'helpers/api-requests/admin';
import { DualCountryListSelector } from '../Common/DualCountryListSelector';
import { OrderTypeServiceSelector } from 'components/OrderType/OrderTypeServiceSelector';
import { OrderTypeVisumIOSelector } from 'components/OrderType/OrderTypeVisumIOSelector';
import { OrderTypeFileInput } from 'components/OrderType/OrderTypeFileInput';

export const StyledModalCancel = styled(StyledButtonCancel)`
  padding: 8px 32px;
`;

export const StyledButtonSave = styled.button`
  padding: 8px 32px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.success};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.danger};
  }

  ${({ $type, theme }) =>
    $type === 'save' &&
    css`
      background-color: ${theme.colors.green500} !important;
    `}
`;

export const ModalOrderTypeDocumentCondition = ({
  handleCloseModal,
  isOpenModal,
  typeButtonConfirm,
  orderTypeData,
  props,
  attachedDocuments,
  availableDocuments,
  setAttAchedDocuments,
  collectedDocuments,
  setCollectedDocuments,
  country = null,
  visa = null,
  buttonText = 'common.save',
  cancelText = 'common.cancel',
}) => {
  const { t, i18n } = useTranslation();
  const { document, condition } = props;
  const [currentVisaTypes, setCurrentVisaTypes] = useState([]);

  // const getCondCtx = condition => {
  //   return {
  //     ...condition,
  //     if_not_product: condition.if_not_product?.value ?? null,
  //     if_product: condition.if_product?.value ?? null,
  //     visa_duration: condition.visa_duration?.value ?? null,
  //   };
  // };

  const getCountryVisaTypes = () => {
    getVisaTypes({ country: country.id })
      .then(response => {
        const results = response?.data?.results ?? [];
        if (results.length > 0) {
          const visaTypes = results.map(({ id, translations, durations }) => ({
            id: id,
            label: translations[i18n.language],
            durations: durations,
          }));
          setCurrentVisaTypes(visaTypes);
          return;
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (country) {
      getCountryVisaTypes();
    }
  }, [country]);

  const handleSubmit = values => {
    let attachedDocument = attachedDocuments.find(
      doc => doc.id === document.id,
    );

    if (!attachedDocument) {
      attachedDocument = availableDocuments.find(doc => doc.id === document.id);
    }

    // update condition
    if (condition?.index) {
      attachedDocument = {
        id: attachedDocument.id,
        translations: attachedDocument.translations,
        conditions: [
          ...attachedDocument.conditions.filter(
            cond => cond.index !== condition.index,
          ),
          {
            ...values,
            visa_duration: values.visa_duration,
            index: condition.index,
          },
        ].sort((a, b) => b.index - a.index),
      };

      // update collectedDocuments

      setCollectedDocuments([
        ...collectedDocuments.filter(
          cond =>
            !(
              cond.country === condition.country &&
              cond.document === condition.document &&
              cond.id === condition.id &&
              cond.if_product?.value === condition.if_product?.value &&
              cond.if_not_product?.value === condition.if_not_product?.value &&
              cond.is_individual === condition.is_individual &&
              cond.is_legal === condition.is_legal &&
              cond.visa_duration?.value === condition.visa_duration?.value &&
              cond.visa_type === condition.visa_type
            ),
        ),
        // { ...getCondCtx(values) },
        { ...values },
      ]);
    } else {
      // or add condition
      attachedDocument = {
        id: attachedDocument.id,
        translations: attachedDocument.translations,
        conditions: [
          ...attachedDocument.conditions,
          { ...values, index: attachedDocument.conditions.length + 1 },
        ],
      };
      setCollectedDocuments([
        ...collectedDocuments,
        // { ...getCondCtx(values) }
        { ...values },
      ]);
    }

    const sortedAttachedDocuments = [
      ...attachedDocuments.filter(doc => doc.id !== document.id),
      attachedDocument,
    ].sort((a, b) => b.id - a.id);

    setAttAchedDocuments(sortedAttachedDocuments);

    handleCloseModal();
  };

  const formikInitData = {
    order_type: orderTypeData.id,
    document: document.id,
    id: condition?.id ?? null,
    require_original: condition?.require_original ?? false,
    is_individual:
      condition?.is_individual ?? orderTypeData.enableForIndividual,
    is_legal: condition?.is_legal ?? orderTypeData.enableForLegal,
    country: orderTypeData.enableCountry ? country.id : null, // should not be optional
    visa_type: orderTypeData.enableVisaType ? visa.id : null,
    visa_duration: orderTypeData.enableVisaType
      ? condition?.visa_duration
      : null,
    valid_nationalities: condition?.valid_nationalities ?? [],

    if_product: condition?.if_product,
    if_not_product: condition?.if_not_product,

    visum_integration: condition?.visum_integration,
    examplePreview: condition?.examplePreview,
    questionnairePreview: condition?.questionnairePreview,

    description_title_en: condition?.description_title_en ?? '',
    description_title_de: condition?.description_title_de ?? '',
    description_source_en: condition?.description_source_en ?? '',
    description_source_de: condition?.description_source_de ?? '',
    description_requirements_en: condition?.description_requirements_en ?? '',
    description_requirements_de: condition?.description_requirements_de ?? '',

    // todo

    require_residency: null,
  };

  const formik = useFormik({
    initialValues: formikInitData,
    validateOnChange: false,
    enableReinitialize: true,
    // validationSchema: SERVICE_VALIDATOR,
    onSubmit: handleSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Modal isOpen={isOpenModal} toggle={handleCloseModal} size="lg" centered>
        <ModalHeader toggle={handleCloseModal} className="border-0 pb-2 p-4">
          Documment condition
        </ModalHeader>

        <div className="w-100 ps-4 pe-4">
          <hr className="m-0" />
        </div>

        <ModalBody className="p-4">
          {
            <>
              <Row>
                <Col xs={12} xl={2} className="p-0">
                  <Label>{t('orderTypes.orderType')}</Label>
                </Col>
                <Col xs={12} xl={10} className="p-0">
                  <Input
                    type="text"
                    disabled
                    value={orderTypeData.translationEn}
                  />
                </Col>
              </Row>

              {/* <div className="d-flex lign-items-center "> */}
              <Row className="mt-2">
                <Col xs={12} xl={2} className="p-0">
                  <Label>{t('orderTypes.document')}</Label>
                </Col>
                <Col xs={12} xl={10} className="p-0">
                  <Input
                    type="text"
                    disabled
                    value={document.translations[i18n.language]}
                  />
                </Col>
              </Row>

              {orderTypeData.enableCountry && (
                <Row className="mt-2">
                  <Col xs={12} xl={2} className="p-0">
                    <Label>{t('orderTypes.country')}</Label>
                  </Col>
                  <Col xs={12} xl={10} className="p-0">
                    <Input type="text" disabled value={country.name} />
                  </Col>
                </Row>
              )}

              <Row className="mt-2">
                <Col xs={12} xl={2} className="p-0">
                  <Label className="m-0 display-5">
                    {t('orderTypes.validNationalities')}
                  </Label>
                </Col>

                <Col xs={12} xl={10} className="p-0">
                  <DualCountryListSelector
                    selected={formik.values.valid_nationalities}
                    onChange={newValue => {
                      formik.setFieldValue('valid_nationalities', newValue);
                    }}
                  />
                </Col>
              </Row>

              {orderTypeData.enableVisaType && (
                <>
                  <Row className="mt-2">
                    <Col xs={12} xl={2} className="p-0">
                      <Label>{t('orderTypes.visaType')}</Label>
                    </Col>
                    <Col xs={12} xl={10} className="p-0">
                      <Input type="text" disabled value={visa.name} />
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col xs={12} xl={2} className="p-0">
                      <Label>{t('orderTypes.visaDuration')}</Label>
                    </Col>

                    <Col xs={12} xl={10} className="p-0">
                      <VisaDurationSelector
                        value={formik.values.visa_duration?.value}
                        visaType={visa.id}
                        onChange={visaDuration => {
                          formik.setFieldValue('visa_duration', visaDuration);
                        }}
                        currentVisaTypes={currentVisaTypes}
                        isClearable={false}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {!formik.values.if_not_product?.value && (
                <Row className="mt-2">
                  <Col xs={12} xl={2} className="p-0">
                    <Label className="m-0 display-5">
                      {t('orderTypes.ifService')}
                    </Label>
                  </Col>
                  <Col xs={12} xl={10} className="p-0">
                    <OrderTypeServiceSelector
                      onChange={product => {
                        formik.setFieldValue('if_product', product);
                        formik.setFieldValue('if_not_product', null);
                      }}
                      value={formik.values.if_product?.value}
                      country={formik.values.country}
                      orderType={formik.values.order_type}
                      helptext={
                        formik.values.if_product
                          ? `If in order, the user selects this service, document '${
                              document.translations[i18n.language]
                            }' must be uploaded`
                          : ''
                      }
                      isClearable
                    />
                  </Col>
                </Row>
              )}

              {!formik.values.if_product?.value && (
                <Row className="mt-2">
                  <Col xs={12} xl={2} className="p-0">
                    <Label className="m-0 display-5">
                      {t('orderTypes.ifNotService')}
                    </Label>
                  </Col>
                  <Col xs={12} xl={10} className="p-0">
                    <OrderTypeServiceSelector
                      onChange={product => {
                        formik.setFieldValue('if_not_product', product);
                        formik.setFieldValue('if_product', null);
                      }}
                      value={formik.values.if_not_product?.value}
                      country={formik.values.country}
                      orderType={formik.values.order_type}
                      helptext={
                        formik.values.if_not_product
                          ? `If in order, the user does not select selects this service, document '${
                              document.translations[i18n.language]
                            }' must be uploaded`
                          : ''
                      }
                      isClearable
                    />
                  </Col>
                </Row>
              )}

              {/* Visum IO servie is always related to Visa orders */}
              {formik.values.country && formik.values.visa_type && (
                <Row className="mt-2">
                  <Col xs={12} xl={2} className="p-0">
                    <Label className="m-0 display-5">
                      {t('orderTypes.visumIOIntegration')}
                    </Label>
                  </Col>
                  <Col xs={12} xl={10} className="p-0">
                    <OrderTypeVisumIOSelector
                      onChange={integration => {
                        formik.setFieldValue(
                          'visum_integration',
                          integration ? integration?.value : null,
                        );
                      }}
                      value={formik.values.visum_integration}
                    />
                  </Col>
                </Row>
              )}

              <hr className="mt-4" />

              <Row className="mt-4">
                <Col xs={12} xl={2} className="p-0">
                  <Label className="m-0 display-5">
                    {t('orderTypes.example')}
                  </Label>
                </Col>
                <Col xs={12} xl={10} className="p-0">
                  <OrderTypeFileInput name="example" accept="image/*" />
                  {formik.values.examplePreview && (
                    <>
                      <a
                        href={`${process.env.REACT_APP_API_URL}${formik.values.examplePreview}`}
                      >
                        <i className="bx bxs-download" />{' '}
                        {t('orderTypes.download')}
                      </a>

                      <i
                        style={{ marginLeft: '40px', cursor: 'pointer' }}
                        className="ml-5 bx bxs-trash"
                        onClick={() => {
                          formik.setFieldValue('example', '');
                          formik.setFieldValue('examplePreview', '');
                        }}
                      />
                    </>
                  )}
                </Col>
              </Row>

              <Row className="mt-2">
                <Col xs={12} xl={2} className="p-0">
                  <Label className="m-0 display-5">
                    {t('orderTypes.questionnaire')}
                  </Label>
                </Col>
                <Col xs={12} xl={10} className="p-0">
                  <OrderTypeFileInput
                    name="questionnaire"
                    accept="application/pdf"
                  />
                  {formik.values.questionnairePreview && (
                    <>
                      <a
                        href={`${process.env.REACT_APP_API_URL}${formik.values.questionnairePreview}`}
                      >
                        <i className="bx bxs-download" /> Download file
                      </a>
                      <i
                        style={{ marginLeft: '40px', cursor: 'pointer' }}
                        className="ml-5 bx bxs-trash"
                        onClick={() => {
                          formik.setFieldValue('questionnaire', '');
                          formik.setFieldValue('questionnairePreview', '');
                        }}
                      />
                    </>
                  )}
                </Col>
              </Row>

              <hr />

              <Row className="mt-4">
                <Col xs={12} xl={2} className="p-0">
                  <Label className="m-0 display-5">
                    {t('orderTypes.descriptionTitleEn')}
                  </Label>
                </Col>
                <Col xs={12} xl={10} className="p-0">
                  <DraftEditor
                    initialValue={formik.values.description_title_en}
                    onChange={txt => {
                      formik.setFieldValue(
                        'description_title_en',
                        draftToHtml(txt),
                      );
                    }}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={12} xl={2} className="p-0">
                  <Label className="m-0 display-5">
                    {t('orderTypes.descriptionTitleDe')}
                  </Label>
                </Col>
                <Col xs={12} xl={10} className="p-0">
                  <DraftEditor
                    initialValue={formik.values.description_title_de}
                    onChange={txt => {
                      formik.setFieldValue(
                        'description_title_de',
                        draftToHtml(txt),
                      );
                    }}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={12} xl={2} className="p-0">
                  <Label className="m-0 display-5">
                    {t('orderTypes.descriptionSourceEn')}
                  </Label>
                </Col>
                <Col xs={12} xl={10} className="p-0">
                  <DraftEditor
                    initialValue={formik.values.description_source_en}
                    onChange={txt => {
                      formik.setFieldValue(
                        'description_source_en',
                        draftToHtml(txt),
                      );
                    }}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={12} xl={2} className="p-0">
                  <Label className="m-0 display-5">
                    {t('orderTypes.descriptionSourceDe')}
                  </Label>
                </Col>
                <Col xs={12} xl={10} className="p-0">
                  <DraftEditor
                    initialValue={formik.values.description_source_de}
                    onChange={txt => {
                      formik.setFieldValue(
                        'description_source_de',
                        draftToHtml(txt),
                      );
                    }}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={12} xl={2} className="p-0">
                  <Label className="m-0 display-5">
                    {t('orderTypes.descriptionRequirementsEn')}
                  </Label>
                </Col>
                <Col xs={12} xl={10} className="p-0">
                  <DraftEditor
                    initialValue={formik.values.description_requirements_en}
                    onChange={txt => {
                      formik.setFieldValue(
                        'description_requirements_en',
                        draftToHtml(txt),
                      );
                    }}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={12} xl={2} className="p-0">
                  <Label className="m-0 display-5">
                    {t('orderTypes.descriptionRequirementsDe')}
                  </Label>
                </Col>
                <Col xs={12} xl={10} className="p-0">
                  <DraftEditor
                    initialValue={formik.values.description_requirements_de}
                    onChange={txt => {
                      formik.setFieldValue(
                        'description_requirements_de',
                        draftToHtml(txt),
                      );
                    }}
                  />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col
                  xs={6}
                  xl={3}
                  className="p-0 d-flex align-items-center v-align-top"
                >
                  <Label className="m-0 display-5">
                    {t('orderTypes.requireOriginal')}
                  </Label>
                </Col>
                <Col xs={6} xl={9} className="p-0">
                  <Input
                    className="m-3"
                    type="checkbox"
                    checked={formik.values.require_original}
                    onChange={() =>
                      formik.setFieldValue(
                        'require_original',
                        !formik.values.require_original,
                      )
                    }
                  />
                </Col>
              </Row>

              {orderTypeData.enableForIndividual && (
                <Row className="mt-2">
                  <Col
                    xs={6}
                    xl={3}
                    className="p-0 d-flex align-items-center v-align-top"
                  >
                    <Label className="m-0 display-5">
                      {t('orderTypes.isIndividual')}
                    </Label>
                  </Col>
                  <Col xs={6} xl={9} className="p-0">
                    <Input
                      className="m-3"
                      type="checkbox"
                      checked={formik.values.is_individual}
                      onChange={() =>
                        formik.setFieldValue(
                          'is_individual',
                          !formik.values.is_individual,
                        )
                      }
                    />
                  </Col>
                </Row>
              )}

              {orderTypeData.enableForLegal && (
                <Row className="mt-2">
                  <Col
                    xs={6}
                    xl={3}
                    className="p-0 d-flex align-items-center v-align-top"
                  >
                    <Label className="m-0 display-5">
                      {t('orderTypes.isLegal')}
                    </Label>
                  </Col>
                  <Col xs={6} xl={9} className="p-0">
                    <Input
                      className="m-3"
                      type="checkbox"
                      checked={formik.values.is_legal}
                      onChange={() =>
                        formik.setFieldValue(
                          'is_legal',
                          !formik.values.is_legal,
                        )
                      }
                    />
                  </Col>
                </Row>
              )}

              <div className="d-flex flex-nowrap align-items-center mt-4">
                <StyledModalCancel
                  className="text-nowrap me-3"
                  onClick={handleCloseModal}
                >
                  {t(cancelText)}
                </StyledModalCancel>

                <StyledButtonSave
                  $type={typeButtonConfirm}
                  onClick={formik.handleSubmit}
                  className="btn me-3 text-nowrap"
                >
                  {t(buttonText)}
                </StyledButtonSave>
              </div>
            </>
          }
        </ModalBody>
      </Modal>
    </FormikProvider>
  );
};
