import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import { SelectApplicantsAddresses } from './SelectApplicantsAddresses';
import { Select } from 'components/Common';
import { DELIVERY_INDIVIDUAL_METHODS } from './ApplicantMainInfo';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ApplicantDeliveryForm } from './ApplicantDeliveryForm';

export const ApplicantAddressForm = ({
  currentApplicant,
  index,
  isApplicantInWork,
}) => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  const applicantAddresses = formik.values.applicants.filter(
    applicant =>
      applicant?.applicantDetail?.id !== currentApplicant?.applicantDetail.id &&
      applicant?.address &&
      applicant?.deliveryMethod === DELIVERY_INDIVIDUAL_METHODS[2].value,
  );

  const isRelatesApplicants = formik.values.applicants.some(
    applicant =>
      applicant.addressAnotherApplicant ===
      currentApplicant?.applicantDetail.id,
  );

  const isDisabledFields = !formik.values.orderDetail?.isEditable;

  return (
    <>
      <FormGroup row className="align-items-center mt-4">
        <Col lg={4} xxl={2} className="d-flex align-items-center">
          <Label className="display-5 m-0">{t('delivery.method')}</Label>
        </Col>

        <Col lg={8} xxl={10}>
          <Select
            isClearable
            className="w-100"
            menuPosition="fixed"
            options={
              applicantAddresses.length > 0
                ? DELIVERY_INDIVIDUAL_METHODS
                : DELIVERY_INDIVIDUAL_METHODS.filter(
                    method =>
                      method.value !== DELIVERY_INDIVIDUAL_METHODS[1].value,
                  )
            }
            placeholder={t('order.methodPlaceholder')}
            isDisabled={
              isApplicantInWork || isRelatesApplicants || isDisabledFields
            }
            invalid={formik.errors.applicants?.[index]?.deliveryMethod}
            value={DELIVERY_INDIVIDUAL_METHODS.find(
              item => item.value === currentApplicant?.deliveryMethod,
            )}
            onChange={event => {
              formik.setFieldValue(
                `applicants[${index}].deliveryMethod`,
                event ? event.value : null,
              );
              formik.setFieldValue(`applicants[${index}].address`, null);
              formik.setFieldValue(
                `applicants[${index}].addressAnotherApplicant`,
                null,
              );

              if (event?.value === 'delivery_method_to_address') {
                formik.setFieldValue(
                  `applicants[${index}].address.mailBox`,
                  'yes',
                );
                formik.setFieldValue(
                  `applicants[${index}].address.deliveryMethod`,
                  event.value,
                );
              }

              // clean delivery services if delivery method won't be to address
              if (event?.value !== 'delivery_method_to_address') {
                const formikServices = formik.values.applicants[
                  index
                ].services.filter(
                  item => item.product_type.type !== 'delivery',
                );

                formik.setFieldValue(
                  `applicants[${index}].services`,
                  formikServices,
                );
              }
            }}
            classNamePrefix="select2-selection"
          />
        </Col>
      </FormGroup>

      {currentApplicant?.deliveryMethod ===
        DELIVERY_INDIVIDUAL_METHODS[1].value && (
        <FormGroup row className="align-items-center mt-4">
          <Col lg={4} xxl={2} className="d-flex align-items-center">
            <Label className="display-5 m-0">
              {t('order.applicantsAddresses')}
            </Label>
          </Col>

          <Col lg={8} xxl={10}>
            <SelectApplicantsAddresses
              invalid={
                formik.errors.applicants?.[index]?.addressAnotherApplicant
              }
              isDisabled={isApplicantInWork || isDisabledFields}
              onChange={option =>
                formik.setFieldValue(
                  `applicants[${index}].addressAnotherApplicant`,
                  option ? option.value : null,
                )
              }
              value={currentApplicant?.addressAnotherApplicant}
              applicantsWithAddresses={applicantAddresses}
            />
          </Col>
        </FormGroup>
      )}

      {currentApplicant?.deliveryMethod ===
        DELIVERY_INDIVIDUAL_METHODS[2].value && (
        <ApplicantDeliveryForm
          disabled={isApplicantInWork || isDisabledFields}
          currentApplicant={currentApplicant}
          indexApplicant={index}
        />
      )}
    </>
  );
};
